const BaseConfig = {
  ENGAGE_APPLE_STORE: "https://apps.apple.com/us/app/veeva-engage/id1155146582",
  ENGAGE_GOOGLE_PLAY:
    "https://play.google.com/store/apps/details?id=com.veeva.engage",
  ENGAGE_CONNECT_APPLE_STORE:
    "https://apps.apple.com/us/app/engage-connect-by-veeva/id1565084541",
  ENGAGE_CONNECT_GOOGLE_PLAY:
    "https://play.google.com/store/apps/details?id=com.veeva.myveeva.forlifesciences",
  QUICK_START: "/quick-start",
};

const DevConfigs = {
  SIGNIN: "https://myveeva-local.com/web/login",
  JOINMEETING: "https://qa.veevaengagedev.com/web/join",
};

const ProdConfigs = {
  SIGNIN: "https://app.veevaengage.com/web/login",
  JOINMEETING: "https://app.veevaengage.com/web/join",
};

const config =
  process.env.REACT_APP_STAGE === "production" ? ProdConfigs : DevConfigs;

const Config = {
  ...BaseConfig,
  ...config,
};

export default Config;
